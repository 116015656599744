import styled from "@emotion/styled";
import * as React from "react";
import { Anchors } from "../anchors";
import { mainBackgroundColor } from "../constants";
import { useScrollSpyMarker } from "../hooks/use-scrollspy";
import { Column } from "./grid/column";
import { Row } from "./grid/row";
import { Container } from "./container";
import { H3, Header } from "./header";
import { Person } from "./person";
import { UpwardsLeftTriangle } from "./triangle";
import { Link } from "./link";

const DonationsContainer = styled.div`
  margin: 5rem 0 5rem 0;
  font-size: 1.5em;

`;
const PersonsContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 0rem;
`;

const LinkContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 0rem;
  font-size: 1.2em;
`;

const LevelContainer = styled.div`
  & + & {
    margin-top: 1rem;
    border-top: 1px dashed #ccc;
    padding-top: 1rem;
  }
`;

const RowContainer = styled.div`
  margin: 4rem 0;
`;

interface IPersonContainer {
  containerWidth: string;
}

const PersonContainer = styled.div<IPersonContainer>`
  padding: 0.5rem;
  text-align: center;
  margin: auto;

  ${(props) => `
    width: ${props.containerWidth};
  `}
`;

interface IPerson {
  width?: string;
  imageURL: string;
  name: string;
  url: string;
}

interface IPersonList {
  width: string;
  lines: IPerson[][];
}

export const Ansprechpartner = () => {
  const marker = useScrollSpyMarker(Anchors.Kontaktperson);
  const levels: IPersonList[] = [
    {
      lines: [
        [
          {
            imageURL: "images/vorstand/Paul.png",
            name: "Paul Intveen",
            url: "mailto:paul.intveen@blind.ski/",
            width: "60%",
            role: "Info / Anfragen",
            email: "paul.intveen@blind.ski",
          },
          {
            imageURL: "images/vorstand/Rolf.png",
            name: "Rolf Kroseberg",
            url: "mailto:rolf.kroseberg@blind.ski/",
            width: "60%",
            role: "1. Vorstand",
            email: "rolf.kroseberg@blind.ski",
          },

        ],
        [
          {
            imageURL: "images/vorstand/Bibi.png",
            name: "Bianca Kroseberg",
            url: "mailto:bianca.kroseberg@blind.ski/",
            width: "60%",
            role: "Mitgliederverwaltung",
            email: "bianca.kroseberg@blind.ski",
          },
          {
            imageURL: "images/vorstand/Daniel.png",
            name: "Daniel Meuer",
            url: "mailto:daniel.meuer@blind.ski/",
            width: "60%",
            role: "2. Vorstand",
            email: "daniel.meuer@blind.ski",
          },

        ],
      ],
      width: "80%",
    },
  ];
  const renderedLevels = levels.map(
    ({ width: levelWidth, lines }, levelIndex) => {
      const renderedLines = lines.map((line, lineIndex) => {
        const renderedPersons = line.map(
          ({ imageURL, name, url, width, role, email }, personIndex) => (
            <Column key={personIndex} grow={1}>
              <PersonContainer containerWidth={levelWidth}>
                <Person
                  width={width}
                  imageURL={imageURL}
                  name={name}
                  url={url}
                  role={role}
                  email={email}
                />
              </PersonContainer>
            </Column>
          ),
        );

        return (
          <div key={lineIndex}>
            <Row center>{renderedPersons}</Row>
          </div>
        );
      });

      return (
        <LevelContainer key={levelIndex}>
          <RowContainer>{renderedLines}</RowContainer>
        </LevelContainer>
      );
    },
  );

  return (
    <Container>
      <PersonsContainer>
        {marker}
        <Header title="Kontakt" subtitle="" />

        {renderedLevels}
      </PersonsContainer>

      <Header title="Downloads" subtitle="Wir sind transparent" />

      <LinkContainer>
        <Link
          label="Gemeinnützigkeitsbescheinigung"
          to="https://blindenskisport.sharepoint.com/:b:/s/agit/EXf6ysRlhgZJgbOxidr01EABKu6_GjkKKmziKfw5QzzhSA?e=FeSzzt"
        />
      </LinkContainer>
      <LinkContainer>
        <Link
          label="Vereinssatzung"
          to="https://blindenskisport.sharepoint.com/:w:/s/agit/EbHVlPh87OlNhP4Sy9t-x0IBdodVHDfZtgaz1iZ4bbYUlg?e=ykytM4"
        />
      </LinkContainer>
      <LinkContainer>
        <Link
          label="Beitrittserklärung"
          to="https://blindenskisport.sharepoint.com/:w:/s/agit/EWa8sz1IkwdAkIJKfnR81VUBvLqF-VzuWCAEzIC6KRIvaA?rtime=4nG0ah-a2Eg"
        />

      </LinkContainer>


      <DonationsContainer>
        <Header title="Spenden" subtitle="Wir freuen uns über jede Spende" />
          {/*  Sparkasse Regensburg
          IBAN: DE47 7505 0000 0027 2361 73
          BIC: BYLADEM1RBG */}
        <p>
          Sparkasse Regensburg
          <br />
          IBAN: DE47 7505 0000 0027 2361 73
          <br />
          BIC: BYLADEM1RBG
        </p>
      </DonationsContainer>
    </Container>
  );
};
