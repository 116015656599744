import * as React from "react";
import { Anchors } from "../anchors";
import { About } from "../components/about";
import { Aftermovie } from "../components/skimovie";
import { FAQ } from "../components/faq";
import { Landing } from "../components/landing";
import { Navbar } from "../components/navbar";
import { Page } from "../components/page";
import { Ansprechpartner } from "../components/ansprechpartner";
import { Sponsors } from "../components/sponsors";
import { TracksPlaceholder } from "../components/tracks-placeholder";

export default () => (
  <Page>
    <Navbar
      items={[
        {
          label: "Über uns",
          marker: Anchors.About,
        },
        {
          label: "Video/Slogen",
          marker: Anchors.Function,
        },
        {
          label: "Kontakt",
          marker: Anchors.Kontaktperson,
        },
        {
          label: "Fragen",
          marker: Anchors.FAQ,
        },
        {
          label: "Partner",
          marker: Anchors.Sponsors,
        },
      ]}
    />

    <Landing isApplicationOpen={false} />
    <About />
    <Aftermovie />
    <TracksPlaceholder />
    <Ansprechpartner />
    <FAQ />
    <Sponsors />
  </Page>
);
