import styled from "@emotion/styled";
import * as React from "react";
import { Anchors } from "../anchors";
import { breakpointMobile, breakpointTablet } from "../constants";
import { useScrollSpyMarker } from "../hooks/use-scrollspy";
import { Header } from "./header";
import { Image } from "./image";
import { Link } from "./link";

const AboutContainer = styled.section`
  position: relative;
  overflow-x: hidden;
`;

const TowerContainer = styled.div`
  position: absolute;
  left: -14.5rem;
  width: 30rem;

  @media screen and (max-width: ${breakpointTablet}) {
    display: none;
  }
`;

const FlagContainer = styled.div`
  position: absolute;
  top: 15rem;
  bottom: 15rem;
  right: -3rem;
  width: 15rem;

  @media screen and (max-width: ${breakpointMobile}) {
    display: none;
  }
`;

const Content = styled.div`
  padding: 5rem 0 3rem 0;
  width: 60rem;
  margin: 0 auto;
  font-size: 2rem;

  @media screen and (max-width: ${breakpointMobile}) {
    width: 80%;
  }
`;

export const About = () => {
  const marker = useScrollSpyMarker(Anchors.About);
  return (
    <AboutContainer>
      {marker}
      <TowerContainer>

      </TowerContainer>

      <FlagContainer>

      </FlagContainer>

      <Content>
        <Header title="Der Blindenskisport begrüßt euch!" subtitle="" />
        Hier findest du Informationen rund um Blindenskisport und zum Förderverein Blindenskisport  – Lappersdorf e.V. Wir arbeiten gerade am Aufbau der Seite und freuen uns über Anregungen
        <br/>
        ({" "}
        <Link
          label="info@blind.ski"
          to="mailto:info@blind.ski"
        />
       {" "})
      </Content>
    </AboutContainer>
  );
};
