import styled from "@emotion/styled";
import * as React from "react";
import { Anchors } from "../anchors";
import { breakpointMobile } from "../constants";
import { useScrollSpyMarker } from "../hooks/use-scrollspy";
import { Container } from "./container";
import { Column } from "./grid/column";
import { Row } from "./grid/row";
import { Header } from "./header";
import { Image } from "./image";
import { Link } from "./link";
import { Question } from "./question";

const FAQContainer = styled.section`
  margin: 10rem 0;
  position: relative;
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: -3rem;
  right: 2rem;
  width: 15rem;

  @media screen and (max-width: ${breakpointMobile}) {
    display: none;
  }
`;

const QuestionsContainer = styled.div`
  padding-right: 2rem;

  @media screen and (max-width: ${breakpointMobile}) {
    padding: 0;
  }
`;

const List = styled.ul``;
const Item = styled.li``;

const More = styled.div`
  text-align: center;
  margin: 2rem 0;
`;

export const FAQ = () => {
  const marker = useScrollSpyMarker(Anchors.FAQ);

  return (
    <FAQContainer>
      {marker}
      <Container>
        <Header title="Antworten auf häufig gestellte Fragen" subtitle=" " />
        <Row>
          <Column grow={1}>
            <QuestionsContainer>
              <Question title="Wie funktioniert Blindenskisport?">
              Damit Blinde oder Sehbehinderte Skifahren können, brauchen sie auf der Piste eine ständige Begleitung (Guide). Beide tragen eine auffällige Warnweste und sind damit als Team gut sichtbar gekennzeichnet. Der Guide fährt vor, der blinde / sehbehinderte Skifahrer folgt dem Guide mit kurzem Abstand. Der Sehbehinderte muss dem Guide möglichst genau in dessen Spur hinterher fahren. Der Guide gibt dem Sehbehinderten mit einigen wenigen Standardkommandos die Richtung, den Schwung und das Anhalten vor. Dazu benutzt der Guide bei blinden Skifahrern einen Lautsprecher, den er auf dem Rücken trägt. Seine Stimme wird durch ein Mikrofon am Helm aufgefangen, an den Lautsprecher übertragen und dann laut und deutlich nach hinten abgestrahlt. Aus der Kombination der Kommandos, der Richtung und Lautstärke des Tons ergibt sich für den blinden Skifahrer eine Tonspur, der er folgen kann und muss. Für Sehbehinderte, die noch ausreichend sehen und die Umrisse und Bewegung des Guides noch erkennen können, kann anstatt des Lautsprechers eine Funkverbindung genutzt werden, um die Kommandos an den Sehbehinderten zu übertragen. Der Sehbehinderte konzentriert sich mit seinem Restsehvermögen auf seinen Guide und kann ihm mit Unterstützung der helfenden Kommandos sicher in der Spur folgen.
              </Question>

              <Question title="Welche Ausrüstung wird benutzt?">
              Übliche Skiausrüstung und Skibekleidung. Der Helm und die Warnweste sind Pflicht. Empfohlen wird das Tragen eines Rückenprotektors. Lautsprecher mit Mikrofon oder Funk-Headsets können nach Verfügbarkeit geliehen werden. Wenn jemand häufiger blind oder sehbehindert zum Skifahren geht, empfiehlt sich auf Dauer die Anschaffung eigener Technik. Der Verein ist dabei gerne behilflich.
              </Question>

            </QuestionsContainer>
          </Column>

          <Column grow={1}>
            <QuestionsContainer>
              <Question title="Welche Voraussetzungen muss ich als blinder oder sehbehinderter Skifahrer erfüllen? ">
              Du solltest sportlich sein, Spaß an der Bewgung im Schnee haben. Du musst Vertrauen  zu einem Menschen aufbauen können, den du nicht kennst. Du bist es gewohnt, dich frei zu bewegen und kannst zum Beispiel alleine vom Boden wieder aufstehen oder sicher ohne Hilfe eine Treppe herauf oder herunter gehen.
              </Question>

              <Question title="Welche Voraussetzungen muss ich erfüllen, wenn ich das Begleiten von blinden und sehbehinderten Skifahrern lernen und anwenden will? ">
              Du bist selbst ein begeisterter sicherer Skifahrer. Du bist so sicher auf Ski, dass du in der Abfahrt über die Schulter nach hinten sehen kannst, um Richtung und Abstand zum Blinden oder Sehbehinderten zu kontrollieren. Du kannst dich gut konzentrieren und hast immer den Überblick über das Geschehen und Hindernisse auf der Piste. Du hast Spaß an der Herausforderung für den Menschen hinter dir einen guten gemeinsamen Weg zu finden. Du möchtest auch gerne erleben, welche Freude und Glücksgefühle beide am Skifahren haben.
              </Question>
              <Question title="Wie lange dauert die Ausbildung und wie oft wird sie angeboten?">
                Die Ausbildung findet jährlich im November statt. Veranstaltungsort ist der Stubaier Gletscher. Anreise am Freitag bis 18:00 und Ende am Sonntag gegen 15:00.
              </Question>
            </QuestionsContainer>
          </Column>
        </Row>
        <QuestionsContainer>
        <More>
        <Question title="Wen kann ich fragen, wenn ich weitere Fragen habe?">
          Sende uns eine eMail mit deinen Kontaktdaten an {" "}
          <Link
            label="info@blind.ski"
            to="mailto:info@blind.ski"
          />
         {" "} - wir freuen uns über alle Fragen von Interessierten, Betroffenen, Lehrern, Vereinen usw.
         </Question>
        </More>
        </QuestionsContainer>
      </Container>
    </FAQContainer>
  );
};
