import styled from "@emotion/styled";
import * as React from "react";
import { Anchors } from "../anchors";
import { useScrollSpyMarker } from "../hooks/use-scrollspy";
import { Container } from "./container";
import { Header } from "./header";

const TracksContainer = styled.div`
  margin: 1rem 0 1rem 0;
  font-size: 1.5em;

`;

export const TracksPlaceholder = () => {
  

  return (
    <Container>
      <TracksContainer>

        <Header title="Was wir anbieten und wer sich angesprochen fühlen sollte" subtitle="Der FBL ist ein gemeinnütziger Verein, dessen Zweck die Förderung des Blindenskisports ist." />
        <Header title="" subtitle="In Zusammenarbeit mit dem TSV Kareth-Lappersdorf bietet der FBL:" />
        <p>
         <li>Unterstützung für Blinde, Sehbehinderte und deren Begleiter mit Interesse am Skisport.</li>
         <li>Ausbildung zur selbstständigen Begleitung von blinden und sehbehinderten Skifahrern </li>
         <li>Vermittlung von Skikursen für blinde und sehbehinderte  Skifahrer (Anfänger und Fortgeschrittene).</li>
         <li>Vermittlung von ausgebildeten Skilehrern zur Begleitung von blinden und sehbehinderten Schülern bei Schulfreizeiten.</li>
         <li>Vermittlung von Begleitern für die Teilnahme als blinder oder sehbehinderter Skifahrer an Skifreizeiten des TSV Kareth-Lappersdorf.</li>

        </p>
      </TracksContainer>
    </Container>
  );
};
