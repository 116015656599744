import styled from "@emotion/styled";
import * as React from "react";
import { Anchors } from "../anchors";
import {
  accentColor,
  breakpointMobile,
  breakpointTablet,
  mainBackgroundColor,
  triangleSize,
} from "../constants";
import { useScrollSpyMarker } from "../hooks/use-scrollspy";
import { Header } from "./header";
import { Link } from "./link";
import { DownwardsRightTriangle, UpwardsLeftTriangle } from "./triangle";
import { AutoPlayVideo } from "./video";

const AftermovieContainer = styled.div`
  position: relative;
  margin-bottom: 10rem;

  @media screen and (max-width: ${breakpointMobile}) {
    padding: 5rem 0;
    margin: 0;
  }
`;

const VideoContainer = styled.div`
  @media screen and (max-width: ${breakpointMobile}) {
    display: none;
  }
`;

const TriangleContainer = styled.div`
  position: absolute;
  top: ${triangleSize};
  left: 0;
  right: 0;
`;

const ResponsiveTriangleContainer = styled.div`
  @media screen and (max-width: ${breakpointTablet}) {
    display: none;
  }
`;

const DescriptionContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;

  padding: 2rem;
  width: 45rem;

  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  backdrop-filter: blur(10px);

  @media screen and (max-width: ${breakpointTablet}) {
    top: 50%;
    left: 50%;
  }

  @media screen and (max-width: ${breakpointMobile}) {
    position: static;
    width: auto;
    transform: none;
    margin: 8rem 0;
  }
`;

const AccentText = styled.div`
  color: ${accentColor};
`;

export const Aftermovie = () => {
  const marker = useScrollSpyMarker(Anchors.Function);

  return (
    <AftermovieContainer>
      {marker}

      <VideoContainer>
        <AutoPlayVideo
        src="images/video.mp4"
        label="Ein Video als Hintergrund, in dem mann Blinde und Sehbehinderte Personen beim Skifahren sieht. Eine Version für Blinde lässt sich als Link finden."
        />
      </VideoContainer>

      <TriangleContainer>
        <ResponsiveTriangleContainer>
          <DownwardsRightTriangle color={mainBackgroundColor} />
        </ResponsiveTriangleContainer>
      </TriangleContainer>

      <DescriptionContainer>
        <Header title=" " subtitle="" />
        <p>
        <li>Du bist sehbehindert oder blind und willst Skifahren?</li>
        <li>Du möchtest das Begleiten von blinden und sehbehinderten Skifahrern lernen?</li>
        <li>Du möchtest blinde und sehbehinderte Skifahrer begleiten?</li>
        <li>Du suchst für eine Schulfreizeit eine ausgebildete Begleitung zum Skifahren?</li>
        <li>Du willst den Blindenskisport durch eine Spende oder Mitgliedschaft fördern?</li>
        <br/>
            &#10149;	Dann bist du bei uns genau richtig!

            <p>

            </p>
        </p>

      </DescriptionContainer>

      <ResponsiveTriangleContainer>
        <UpwardsLeftTriangle color={mainBackgroundColor} />
      </ResponsiveTriangleContainer>
    </AftermovieContainer>
  );
};
