import styled from "@emotion/styled";
import * as React from "react";
import { Image } from "./image";
import { Link } from "./link";
import { Container } from "./container";
import { Row } from "./grid/row";

interface IPersonProps {
  imageURL: string;
  name: string;
  url: string;
  width?: string;
  role: string;
  email: string;
}

const NameContainer = styled.div`
  padding-bottom: 0.4rem;
  font-size: 1.3em;
`;
const EmailContainer = styled.div`
  padding-bottom: 0.1rem;
  font-size: 0.7em;
`;


export const Person = ({ imageURL, name, url, width, role, email }: IPersonProps) => (
  <Link label={name} target="_blank" to={url}>
    <Row>
    <Container>
    <Image label={`Portrait von ${name}`} src={imageURL} width={width} height="auto" />
    </Container>
    <Container>
    <NameContainer>{name}</NameContainer>
    {role}
    <EmailContainer>{email}</EmailContainer>
    </Container>
    </Row>
  </Link>
);
