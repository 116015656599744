import styled from "@emotion/styled";
import * as React from "react";
import { Anchors } from "../anchors";
import { mainBackgroundColor } from "../constants";
import { useScrollSpyMarker } from "../hooks/use-scrollspy";
import { Column } from "./grid/column";
import { Row } from "./grid/row";
import { Header } from "./header";
import { Sponsor } from "./sponsor";
import { UpwardsLeftTriangle } from "./triangle";

const SponsorsContainer = styled.div`
  background-color: white;
  color: ${mainBackgroundColor};
  padding-bottom: 5rem;
`;

const LevelContainer = styled.div`
  & + & {
    margin-top: 1rem;
    border-top: 1px dashed #ccc;
    padding-top: 1rem;
  }
`;

const RowContainer = styled.div`
  margin: 4rem 0;
`;

interface ISponsorContainer {
  containerWidth: string;
}

const SponsorContainer = styled.div<ISponsorContainer>`
  padding: 1rem;
  text-align: center;
  margin: auto;

  ${(props) => `
    width: ${props.containerWidth};
  `}
`;

interface ISponsor {
  width?: string;
  imageURL: string;
  name: string;
  url: string;
}

interface ISponsorList {
  width: string;
  lines: ISponsor[][];
}


export const Sponsors = () => {
  const marker = useScrollSpyMarker(Anchors.Sponsors);
  const levels: ISponsorList[] = [
    {
      lines: [
        [
          {
            imageURL: "images/partner/Skiabteilung.svg",
            name: "Skiabteilung TSV Kareth-Lappersdorf",
            url: "https://ski-lappersdorf.de/",
            width: "50%",
            role: "Partner",
          },
          {
            imageURL: "images/partner/TSV.svg",
            name: "TSV Kareth Lappersdorf",
            url: "http://www.tsv-karethlappersdorf.de/",
            width: "50%",
            role: "Partner",
          },
        ],

      ],
      width: "80%",
    },
  ];

  const renderedLevels = levels.map(
    ({ width: levelWidth, lines }, levelIndex) => {
      const renderedLines = lines.map((line, lineIndex) => {
        const renderedSponsors = line.map(
          ({ imageURL, name, url, width }, sponsorIndex) => (
            <Column key={sponsorIndex} grow={1}>
              <SponsorContainer containerWidth={levelWidth}>
                <Sponsor
                  width={width}
                  imageURL={imageURL}
                  name={name}
                  url={url}
                />
              </SponsorContainer>
            </Column>
          ),
        );

        return (
          <div key={lineIndex}>
            <Row center>{renderedSponsors}</Row>
          </div>
        );
      });

      return (
        <LevelContainer key={levelIndex}>
          <RowContainer>{renderedLines}</RowContainer>
        </LevelContainer>
      );
    },
  );

  return (
    <SponsorsContainer>
      <UpwardsLeftTriangle color="white" />

      {marker}
      <div style={{ textAlign: "center" }}>
        <Header title="Partner" subtitle="" />
      </div>

      {renderedLevels}
    </SponsorsContainer>
  );
};
